import * as React from 'react';
import styled from 'styled-components';

import { flexGap, verticalSpace } from '@doltech/utils/lib/css-style';
import { fromScreen } from '@doltech/core/lib/responsive/responsive.util';
import { LoginProvider } from '@doltech/core/lib/auth/constants';
import { useAuthManager } from '@doltech/core/lib/auth/authManager';
import { IconSystemV2BrandsGoogle } from '@doltech/icons/SystemV2/IconSystemV2BrandsGoogle';
import { useDolSliderControl } from '@doltech/ui/lib/figma/DolSlider/useDolSliderControl';
import { ResponsiveTypography } from '@doltech/ui/lib/figma/Typography/v3/ResponsiveTypography';
import { ButtonGeneral } from '@doltech/ui/lib/figma/Button/ButtonGeneral/ButtonGeneral';
import { DolSimpleSlider } from '@doltech/ui/lib/figma/DolSlider/DolSimpleSlider/DolSimpleSlider';
import { ItemDotSlider } from '@doltech/ui/lib/figma/DolSlider/ItemDotSlider/ItemDotSlider';
import { DolLogoIeltsSatJuniorLMSHome } from '@doltech/ui/lib/figma/DolLogo/DolLogoIeltsSatJunior';

const AUTO_SLIDE_TIME_IN_SECONDS = 3.25;

const DotSlider = styled.div`
  display: flex;
  justify-content: center;
  ${flexGap(8)}
`;

const Banner = styled.div`
  img {
    width: 100%;
    object-fit: cover;
  }
`;

const InfoText = styled.div``;

const LoginInfo = styled.div`
  ${verticalSpace(24)}

  ${fromScreen(768)} {
    padding-right: 48px;
  }
`;

const SectionBanner = styled.div``;

const SectionMainInfo = styled.div`
  ${verticalSpace(24)}

  ${fromScreen(768)} {
    ${verticalSpace(40)}
  }

  ${fromScreen(1120)} {
    padding-right: 48px;
  }
`;

const Layout = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  gap: 40px;

  width: 100%;
  max-width: 460px;

  ${fromScreen(1120)} {
    grid-template-columns: repeat(2, minmax(0, 460px));
    align-items: center;
    justify-content: center;

    max-width: none;
  }
`;

const Container = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;

  height: 100%;
  padding: 32px 20px 40px;

  ${fromScreen(768)} {
    align-items: center;
    padding: 40px 24px;
  }

  ${fromScreen(1120)} {
    padding: 20px 24px 40px;
  }
`;

const IMG_SLIDES = [
  'https://gqefcpylonobj.vcdn.cloud/directus-upload/3842e061-79b5-4f70-9b48-b6219f75883d.png',
  'https://gqefcpylonobj.vcdn.cloud/directus-upload/a9164fdd-da6c-45f3-9412-b8409cc7241c.png',
  'https://gqefcpylonobj.vcdn.cloud/directus-upload/1ab85428-c6b7-47fd-a8ba-9fa33b8d5d4c.png',
];

interface LmsStudentLoginProps {
  onLoginSuccess?: () => void;
}

export const LmsStudentLogin = (props: LmsStudentLoginProps) => {
  const { onLoginSuccess } = props;
  const authManager = useAuthManager();
  const { sliderRef, currentSlideIndex } = useDolSliderControl({
    autoSlide: true,
    autoTimeInSeconds: AUTO_SLIDE_TIME_IN_SECONDS,
  });

  return (
    <Container className="lms-student-login">
      <Layout>
        <SectionMainInfo>
          <DolLogoIeltsSatJuniorLMSHome />
          <LoginInfo>
            <InfoText>
              <ResponsiveTypography.Paragraph variant="semi-bold/20-28" color="black100" mb="8px">
                Đăng nhập vào DOL superLMS
              </ResponsiveTypography.Paragraph>
              <ResponsiveTypography.Paragraph variant="regular/14-20" color="gray70">
                Vui lòng đăng nhập bằng Gmail bạn đã đăng ký khóa học tại DOL English để vào khóa
                học.
              </ResponsiveTypography.Paragraph>
            </InfoText>
            <ButtonGeneral
              type="primary"
              size="large"
              iconLeft={<IconSystemV2BrandsGoogle />}
              text="Sign in with Google"
              onClick={() =>
                authManager.login({ provider: LoginProvider.GOOGLE, afterLogin: onLoginSuccess })
              }
            />
          </LoginInfo>
        </SectionMainInfo>

        <SectionBanner>
          <DolSimpleSlider
            ref={sliderRef}
            configs={{
              slidesToShow: 1,
              focusMode: true,
            }}
          >
            {IMG_SLIDES.map((imgUrl, imgIndex) => {
              return (
                <Banner key={imgIndex}>
                  <img alt={`banner_${imgIndex + 1}`} src={imgUrl} />
                </Banner>
              );
            })}
          </DolSimpleSlider>
          <DotSlider>
            {IMG_SLIDES.map((_, imgIndex) => {
              return <ItemDotSlider key={imgIndex} active={imgIndex === currentSlideIndex} />;
            })}
          </DotSlider>
        </SectionBanner>
      </Layout>
    </Container>
  );
};
