import { css } from 'styled-components';
import { fromScreen } from '@doltech/core/lib/responsive/responsive.util';
import { BreakpointModel, ConfigModel } from './types';

const getFocusModeStyle = (focusMode) => {
  if (focusMode) {
    let scrollSnapAlign = 'start';

    if (typeof focusMode === 'string') {
      scrollSnapAlign = focusMode;
    }

    return css`
      > * {
        scroll-snap-align: ${scrollSnapAlign};
      }

      &::after {
        content: "focus";
        position: absolute;
        opacity: 0;
        pointer-events: none;
      }
    `;
  }

  return css`
    > * {
      scroll-snap-align: start;
    }

    &::after {
      content: "no-focus";
      position: absolute;
      opacity: 0;
      pointer-events: none;
    }
  `;
};

export const getConfigStyles = (configs: ConfigModel) => {
  const { gap = 8, slidesToShow = 'auto', focusMode } = configs;
  const columnGap = gap ? `${gap}px` : null;
  let gridAutoColumns = null;

  if (slidesToShow === 'auto') {
    gridAutoColumns = 'auto';
  } else if (slidesToShow === 'max-content') {
    gridAutoColumns = 'max-content';
  } else if (typeof slidesToShow === 'number') {
    gridAutoColumns = `calc((100% - ${gap * (slidesToShow - 1)}px) / ${slidesToShow})`;
  }

  return css`
    column-gap: ${columnGap};
    grid-auto-columns: ${gridAutoColumns};

    ${getFocusModeStyle(focusMode)}
  `;
};

export const getResponsiveConfigStyles = (defaultConfigs: ConfigModel, breakpoints?: BreakpointModel[]) => {
  if (!breakpoints?.length) {
    return getConfigStyles(defaultConfigs);
  }

  return breakpoints
    .sort((a, b) => {
      if (a.breakpoint === 'default') return -1;
      if (b.breakpoint === 'default') return 1;
      return a.breakpoint - b.breakpoint;
    })
    .map((eachBreakpoint, breakpointIndex, arr) => {
      const configs = {
        ...defaultConfigs,
        ...eachBreakpoint.configs,
      };

      if (eachBreakpoint.breakpoint === 'default') {
        return getConfigStyles(configs);
      }

      return css`
        ${fromScreen(eachBreakpoint.breakpoint)} {
          ${getConfigStyles(configs)}
        }
      `;
    });
};
