import * as React from 'react';
import styled from 'styled-components';
import cl from 'classnames';

import { BreakpointModel, ConfigModel } from './types';
import { getResponsiveConfigStyles } from './utils';

const DEFAULT_CONFIGS: ConfigModel = {
  gap: 8,
  slidesToShow: 'auto',
};

const SlideItem = styled.div``;

const Container = styled.div<{ configs?: ConfigModel; breakpoints?: BreakpointModel[]; }>`
  display: grid;
  grid-auto-flow: column;
  overflow-x: auto;

  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  ${(p) => getResponsiveConfigStyles(p.configs, p.breakpoints)}
`;

export interface DolSimpleSliderProps {
  className?: string;
  children?: React.ReactNode;

  activeIndex?: number;

  /**
   * The configs parameter is a ConfigModel object
   * - configs or default configs for slider
   * - optional if using breakpoints (if available -> considered as default value in case other null)
   * - default: DEFAULT_CONFIGS
   *
   * @type {ConfigModel}
   *
   */
  configs?: ConfigModel;

  /**
   * The breakpoints parameter is a BreakpointModel array object
   * - breakpoint using min-width since we use mobile first design
   * - breakpoint value 'default' is considered as default screen css (mobile first)
   * - default: []
   *
   * @type {BreakpointModel[]}
   *
   */
  breakpoints?: BreakpointModel[];
}

export const DolSimpleSlider = React.forwardRef((props: DolSimpleSliderProps, ref: React.ForwardedRef<HTMLDivElement>) => {
  const { className, children, activeIndex = 0, configs = DEFAULT_CONFIGS, breakpoints = [] } = props;
  return (
    <Container
      ref={ref}
      className={cl('dol-simple-slider', className)}
      configs={configs}
      breakpoints={breakpoints}
      data-active-index={activeIndex}
    >
      {React.Children.map(children, (singleSlideNode, slideIndex) => {

        return (
          <SlideItem key={slideIndex} id={`slide-item-${slideIndex + 1}`} data-slide-index={slideIndex} className="dol-simple-slider-item">
            {singleSlideNode}
          </SlideItem>
        );
      })}
    </Container>
  );
});
