import * as React from 'react';
import styled from 'styled-components';
import cl from 'classnames';
import { colorsV2 } from '../../colors-v2';

const Dot = styled.div`
  width: 8px;
  min-width: 8px;
  height: 8px;

  background-color: ${colorsV2.gray20};
  border-radius: 50%;

  &:hover {
    background-color: ${colorsV2.gray60};
  }

  &.active {
    background-color: ${colorsV2.primary100};
  }
`;

interface ItemDotSliderProps {
  active?: boolean;
}

export const ItemDotSlider = (props: ItemDotSliderProps) => {
  const { active = false } = props;

  return <Dot className={cl('item-dot-slider', { active })} />;
};
