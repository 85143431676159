import * as React from 'react';
import { useInterval } from 'react-use';

interface DolSliderControlProps {
  autoTimeInSeconds?: number;
  autoSlide?: boolean;
}

export const useDolSliderControl = (props?: DolSliderControlProps) => {
  const { autoTimeInSeconds = 2, autoSlide = false } = props || {};
  const [slider, setSlider] = React.useState<HTMLElement>(null);
  const [canSlideNext, setCanSlideNext] = React.useState(false);
  const [canSlidePrev, setCanSlidePrev] = React.useState(false);
  const [currentSlideIndex, setCurrentSlideIndex] = React.useState(0);

  const getFocusMode = (sliderNode) => {
    if (sliderNode) {
      const { content } = window.getComputedStyle(sliderNode, '::after');
      const focusContent = content.replace(/"/g, '');
      return focusContent === 'focus';
    }
    return false;
  };

  React.useEffect(() => {
    const handleScroll = (e) => {
      if (!getFocusMode(slider)) {
        setCanSlideNext(
          Math.round(e.target.scrollLeft + e.target.offsetWidth) < e.target.scrollWidth
        );
        setCanSlidePrev(e.target.scrollLeft > 0);
      }
    };

    const handleWheel = (e) => {
      if (getFocusMode(slider)) {
        e.preventDefault();
        e.stopPropagation();
      }
    };

    slider?.addEventListener('scroll', handleScroll, true);
    slider?.addEventListener('wheel', handleWheel, true);

    return () => {
      slider?.removeEventListener('scroll', handleScroll, true);
      slider?.removeEventListener('wheel', handleWheel, true);
    };
  }, [slider]);

  const scrollToSlide = (slide: Element) => {
    if (slide) {
      const { scrollSnapAlign } = window.getComputedStyle(slide);
      let inline = 'start';

      if (scrollSnapAlign && scrollSnapAlign !== 'none') {
        inline = scrollSnapAlign;
      }

      slide?.scrollIntoView({ behavior: 'smooth', block: 'end', inline: inline as any });
    }
  };

  const slideToIndex = (slideIndex) => {
    slider.setAttribute('data-active-index', `${slideIndex}`);
    setCurrentSlideIndex(slideIndex);
    const selectedSlide = slider.children.item(slideIndex);
    scrollToSlide(selectedSlide);
  };

  const sliderRef: React.RefCallback<HTMLElement> = (node) => {
    if (node) {
      const isFirstInit = !slider;
      const activeIndex = Number.parseInt(node.dataset.activeIndex);
      const focusMode = getFocusMode(node);

      setSlider(node);
      setCurrentSlideIndex(activeIndex);

      if (focusMode) {
        setCanSlideNext(activeIndex < node.children.length - 1);
        setCanSlidePrev(activeIndex > 0);

        if (isFirstInit) {
          const selectedSlide = node.children.item(activeIndex);
          scrollToSlide(selectedSlide);
        }
      } else {
        setCanSlideNext(Math.round(node.scrollLeft + node.offsetWidth) < node.scrollWidth);
        setCanSlidePrev(node.scrollLeft > 0);
      }
    }
  };

  const slideNext = () => {
    const focusMode = getFocusMode(slider);
    const activeIndex = Number.parseInt(slider.dataset.activeIndex);
    const nextIndex = activeIndex + 1;

    if (canSlideNext) {
      if (focusMode) {
        slideToIndex(nextIndex);
      } else {
        slider.setAttribute('data-active-index', `${nextIndex}`);
        setCurrentSlideIndex(nextIndex);
        slider.scrollBy({
          behavior: 'smooth',
          left: 50,
        });
      }
    }
  };

  const slidePrev = () => {
    const focusMode = getFocusMode(slider);
    const activeIndex = Number.parseInt(slider.dataset.activeIndex);
    const prevIndex = activeIndex - 1;

    if (canSlidePrev) {
      if (focusMode) {
        slideToIndex(prevIndex);
      } else {
        slider.setAttribute('data-active-index', `${prevIndex}`);
        setCurrentSlideIndex(prevIndex);
        slider.scrollBy({
          behavior: 'smooth',
          left: -50,
        });
      }
    }
  };

  const slideToFirst = () => {
    const firstSlideIndex = 0;
    const focusMode = getFocusMode(slider);
    setCurrentSlideIndex(firstSlideIndex);

    if (focusMode) {
      slideToIndex(firstSlideIndex);
    } else {
      slider.setAttribute('data-active-index', `${firstSlideIndex}`);
      setCurrentSlideIndex(firstSlideIndex);
      slider.scrollBy({
        behavior: 'smooth',
        left: -slider.scrollWidth,
      });
    }
  };

  useInterval(() => {
    if (canSlideNext) {
      slideNext();
    } else {
      slideToFirst();
    }
  }, autoSlide && slider ? autoTimeInSeconds * 1000 : null);

  return {
    sliderRef,
    slideNext,
    slidePrev,
    slideToIndex,
    currentSlideIndex,
    canSlideNext,
    canSlidePrev,
  };
};
