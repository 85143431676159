import { observer } from 'mobx-react-lite';
import { useRouter } from 'next/router';
import * as React from 'react';
import { useQuery } from 'react-query';
import { useGetAuthInfo } from '@doltech/core/lib/auth/withGlobalUserAuthInfo';
import { useAuthManager } from '@doltech/core/lib/auth/authManager';
import { SpokePageSpinner } from '@doltech/core/lib/auth/SpokePageSpinner';

import { getCourseChecks } from '@doltech/domains/lms-student/lib/shared/apis/courses-api';
import { LmsStudentLogin } from '@doltech/domains/lms-student/lib/login/LmsStudentLogin';
import { ResponsiveTypography } from '@doltech/ui/lib/figma/Typography/v3/ResponsiveTypography';
import { environment } from '@doltech/core/lib/environment/environment';

const LoginPage = observer(() => {
  const router = useRouter();
  const { refetch } = useGetAuthInfo();
  const authManager = useAuthManager();
  const isLoggedIn = authManager.isLoggedIn();

  const { isError, error, isLoading } = useQuery<any>(
    ['GET_COURSE_CHECKS', isLoggedIn],
    () => getCourseChecks(),
    {
      enabled: isLoggedIn,
      retry: 1,
      onSuccess: (data) => {
        if (data?.invalidStudent) {
          router.replace('/forbidden');
          return;
        }

        const redirectTo = sessionStorage.getItem('redirectTo');
        if (redirectTo && redirectTo !== `${environment.REACT_APP_DOL_SUPER_LMS_HOST_NAME}/`) {
          sessionStorage.removeItem('redirectTo');
          router.replace(redirectTo);
        } else {
          sessionStorage.removeItem('redirectTo');
          router.replace('/my-classes');
        }
      },
      refetchOnMount: true,
    }
  );

  if (isError) {
    return (
      <div>
        <ResponsiveTypography.Paragraph>
          Don't know why you got to this state, please contact support
        </ResponsiveTypography.Paragraph>
        <textarea>{JSON.stringify(error)}</textarea>
      </div>
    );
  }

  if (isLoading) {
    return <SpokePageSpinner />;
  }

  return <LmsStudentLogin onLoginSuccess={refetch} />;
});

export default LoginPage;
